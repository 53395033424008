import { createAction } from "redux-api-middleware";
const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const QPRO_TOKEN_STATUS_REQUEST = "QPRO_TOKEN_STATUS_REQUEST";
export const QPRO_TOKEN_STATUS_SUCCESS = "QPRO_TOKEN_STATUS_SUCCESS";
export const QPRO_TOKEN_STATUS_FAILURE = "QPRO_TOKEN_STATUS_FAILURE";

export const QPRO_SESSION_REPORT_ID_SUCCESS = "QPRO_SESSION_REPORT_ID_SUCCESS";


export const getQproToken = (
  token,
  session_id,
  qproQuestionnaireId,
  currentPracticeID,
  currentPractice
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/generate_qpro_ecrypted_token?session_id=${session_id}&qpro_questionnaire_id=${qproQuestionnaireId}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      QPRO_TOKEN_STATUS_REQUEST,
      QPRO_TOKEN_STATUS_SUCCESS,
      QPRO_TOKEN_STATUS_FAILURE,
    ],
  });
};

export const getPG4ReportId = (
  token,
  session_id,
  currentPracticeID,
  currentPractice
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/get_qpro_session_report_id?session_id=${session_id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      QPRO_TOKEN_STATUS_REQUEST,
      QPRO_SESSION_REPORT_ID_SUCCESS,
      QPRO_TOKEN_STATUS_FAILURE,
    ],
  });
};



