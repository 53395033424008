import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import LoadIframe from "./LoadIframe";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPG4ReportId, getQproToken } from '../store/actions/qpro';
import { encode }from 'url-safe-base64'
import L_QproPage from './LoadingEffectComponent/Dashboard/Practice/L_QproPage';
import toast from "react-hot-toast";

export const QproPage = (cardInfo) => {
  const location = useLocation();
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  console.log(location.state)


  // redux actions
  const dispatch = useDispatch();
  const QRPO_WEB_HOST = process.env.REACT_APP_QPRO_WEB_HOST + "embed/auth?tenant=PG3";
  const [showLoader, setshowLoader] = useState(true);
  const token = useSelector(state => state.auth.token);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const language = useSelector(state => state.auth.language);
  const qpro_token = (token) => dispatch(getQproToken(token, location.state.session_id, location.state.qpro_questionnaire_id, currentPracticeID, currentPractice)); 
  const qpro_session_report_id= (token) => dispatch(getPG4ReportId(token, location.state.session_id, currentPracticeID, currentPractice)); 
  
  const [appURL, setAppURL]= useState("")

  useEffect(async() => {
    
    const response = await qpro_token(token)

    console.log({response})
    
    if (!response.error) {
        
        setAppURL(QRPO_WEB_HOST + "&token=" + encode(btoa(response?.payload?.qpro_token)))
        setshowLoader(false)
    } 
    else{
        console.log("error----------{response}", {response})
    } 

    const handleMessage = async (event) => {
      // const origin = process.env.REACT_APP_QPRO_WEB_HOST;
      // event?.origin === origin && 

      if (event?.data?.success == true) {
        const response = await qpro_session_report_id(token)
        
        navigate('/patient/msq/status?report_id=' + response?.payload?.recommendation_report_id);
        toast.success('Questionnaire Completed!');   
      }
      else if (event?.data?.success == false) 
      {
        navigate('/patient/dashboard', { replace: true, state: { r_id: 123 } });
        toast.error('Something went wrong!!!');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [])

  
  return (
    <div>
    {
      showLoader
        ? <L_QproPage/>      
        :<div style={{ minHeight: "100vh" }}>     
          <Helmet>
            <title> {language !== "en_gb" ? "PureInsight™" : "PureGenomics®"} - QPRO </title>
          </Helmet>
          {appURL && <LoadIframe
            src={appURL}
            height="100%"
            width="100%"
            scrolling="yes"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"/>
          }
        </div>
    }
    </div>   
    
  );
};

export default QproPage;
