import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

export const SideBar = ({ show: showSidebar, onClose, logout }) => {
  const [showPractitionerMenu, setShowPractitionerMenu] = useState(false);
  const [showPatientMenu, setShowPatientMenu] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.mfaEnabled);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);

  useEffect(() => {
    setShowPractitionerMenu(false);
    setShowPatientMenu(false);
    onClose();
  }, [location]);

  const toggleMenu = (menuState, setMenuState) => setMenuState(!menuState);
  const sidebarVariants = {
    open: {
      x: 0,
      right: 0,
      opacity: 1,
      transition: { duration: 0.2, ease: "easeIn" },
    },
    exit: {
      x: "100%",
      opacity: 0,
      transition: { duration: 0.3, ease: "easeOut" },
    },
    initial: {
      x: "100%",
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      {showSidebar && (
        <motion.aside
          variants={sidebarVariants}
          initial="initial"
          animate="open"
          exit="exit"
          className="pg4-sidebar fixed top-0 right-0 bgc-primary z-5"
          id="sidebar"
          aria-hidden={!showSidebar}
          aria-label="Sidebar Navigation"
          role="navigation"
        >
          {/* Desktop Navigation */}
          <nav aria-label="Main Navigation">
            <div className="text-end close-btn-container">
              <button
                onClick={onClose}
                className="btn"
                aria-label="Close sidebar"
                tabIndex="0"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ul className="links d-flex flex-column h-100" role="menubar">
              <li role="none">
                <button
                  onClick={() =>
                    toggleMenu(showPractitionerMenu, setShowPractitionerMenu)
                  }
                  aria-expanded={showPractitionerMenu}
                  aria-controls="practitioner-submenu"
                  tabIndex="0"
                  className="d-flex justify-content-between align-items-center"
                >
                  <span>Healthcare Provider</span>
                  <motion.i
                    className="fas fa-chevron-right"
                    animate={{ rotate: showPractitionerMenu ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  ></motion.i>
                </button>
              </li>
              {showPractitionerMenu && (
                <ul id="practitioner-submenu" role="menu">
                  <li role="none">
                    <NavLink
                      to="/hcp/about-pureinsight"
                      aria-label="Discover PureInsight"
                      className="pg4-dropdown-menu__item"
                      role="menuitem"
                      tabIndex="0"
                    >
                      Discover PureInsight
                    </NavLink>
                  </li>
                  <li role="none">
                    <NavLink
                      to="/hcp/faqs"
                      aria-label="Healthcare Provider FAQs"
                      className="pg4-dropdown-menu__item"
                      role="menuitem"
                      tabIndex="0"
                    >
                      Healthcare Provider FAQs
                    </NavLink>
                  </li>
                </ul>
              )}
              <li role="none">
                <button
                  className="d-flex justify-content-between align-items-center"
                  onClick={() =>
                    toggleMenu(showPatientMenu, setShowPatientMenu)
                  }
                  aria-expanded={showPatientMenu}
                  aria-controls="patient-submenu"
                  tabIndex="0"
                >
                  <span>Patient</span>
                  <motion.i
                    className="fas fa-chevron-right"
                    animate={{ rotate: showPatientMenu ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                  ></motion.i>
                </button>
              </li>
              {showPatientMenu && (
                <ul id="patient-submenu" role="menu">
                  <li role="none">
                    <NavLink
                      to="/patient/why-pureinsight"
                      aria-label="Why PureInsight"
                      className="pg4-dropdown-menu__item"
                      role="menuitem"
                      tabIndex="0"
                    >
                      Why PureInsight
                    </NavLink>
                  </li>
                  <li role="none">
                    <NavLink
                      to="/patient/faqs"
                      aria-label="Patient FAQs"
                      className="pg4-dropdown-menu__item"
                      role="menuitem"
                      tabIndex="0"
                    >
                      Patient FAQs
                    </NavLink>
                  </li>
                </ul>
              )}
            </ul>
            <div
              className="account-actions"
              role="toolbar"
              aria-label="Account Actions"
            >
              {isAuth && mfaEnabled ? (
                <Link onClick={logout} aria-label="Logout" className="primary">
                  Logout
                </Link>
              ) : (
                <ul className=" d-flex ">
                  <NavDropdown
                    className="d-inline-block text-light my-auto mx-2"
                    title="Register"
                    id="register-dropdown"
                  >
                    <NavDropdown.Item
                      as={Link}
                      className="my-2 w-100 mx-0"
                      to="/hcp/registration"
                    >
                      Practitioner Registration
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      className="my-2 w-100 mx-0"
                      to="/patient/self-register"
                    >
                      Patient Registration
                    </NavDropdown.Item>
                  </NavDropdown>
                </ul>
              )}
              {isAuth && mfaEnabled ? (
                <Link
                  to={`/${
                    userType === "AdminUser"
                      ? "admin"
                      : userType === "User"
                      ? "practice"
                      : "patient"
                  }/${userType === "Patient" ? "dashboard" : "dashboard"}`}
                  className="account-actions__link"
                  aria-label="Dashboard"
                >
                  {t("dashboard")}
                </Link>
              ) : (
                <NavLink
                  to="/users/login"
                  aria-label="Login"
                  className="account-actions__link"
                >
                  Sign in
                </NavLink>
              )}
            </div>
            <div className="pb-2 p-1 text-end">
              <Link
                to="https://www.pureencapsulationspro.com/"
                className="fw-bold text-white "
                style={{
                  fontSize: "12px",
                }}
                target="_blank"
              >
                PURE ENCAPSULATIONS PRO SITE{" "}
                <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </nav>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};
