import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Loading } from "../components/Loading";
// Routes and Layouts
import {
  AdminProtectedRoute,
  PracticeProtectedRoute,
  LoginRoute,
  PatientRoute,
  MFARoute,
} from "../routes/route_constants";
import AdminLayout from "./AdminLayout";
import PracticeLayout from "./PracticeLayout";
import LoginLayout from "./LoginLayout";
import HomePageLayout from "../Layout/HomePageLayout";
import QproPage from "../components/QproPage";

// Lazy loaded components
const HomePage = lazy(() => import("../Pages/HomePage"));
const LoginPage = lazy(() => import("../Pages/Auth/LoginPage"));
const DomainPage = lazy(() => import("../Pages/Auth/Domain"));
const PG2PasswordUpdatePage = lazy(() =>
  import("../Pages/Auth/PG2PasswordUpdatePage")
);
const Pg2UserLandingPage = lazy(() =>
  import("../Pages/Auth/Pg2UserLandingPage")
);
const AdminDashboard = lazy(() => import("../Pages/Admin/Dashboard"));
const AdminPractitionerList = lazy(() =>
  import("../Pages/Admin/Practitioner/PractitionerList")
);
const AdminPatientList = lazy(() =>
  import("../Pages/Admin/Patient/PatientList")
);
const AdminUserList = lazy(() =>
  import("../Pages/Admin/AdminUser/AdminUserList")
);
const ProductionTask = lazy(() => import("../Pages/Admin/ProductionTask"));
const AdminStaffList = lazy(() => import("../Pages/Admin/Staff/StaffList"));
const PractitionerDashboard = lazy(() => import("../Pages/Practice/Dashboard"));
const PractitionerAccount = lazy(() => import("../Pages/Practice/Account"));
const StaffAccount = lazy(() => import("../Pages/Practice/StaffAccount"));
const ForgotPasswordPage = lazy(() =>
  import("../Pages/Auth/ForgotPasswordPage")
);
const AddPatientPage = lazy(() => import("../Pages/Practice/AddPatientPage"));
const PatientList = lazy(() => import("../Pages/Practice/PatientList"));
const StaffList = lazy(() => import("../Pages/Practice/Staff/StaffList"));
const RialtoPage = lazy(() => import("../components/RialtoPage"));
const RialtoManagement = lazy(() => import("../Pages/RialtoManagement"));
const ManagePracticeData = lazy(() =>
  import("../Pages/Practice/ManagePracticeData")
);
const StripeRedirect = lazy(() => import("../Pages/StripeRedirect"));
const ViewPatientAnswers = lazy(() =>
  import("../Pages/Practice/ViewPatientAnswers")
);
const GeneticUpload = lazy(() => import("../Pages/Patient/GeneticUpload"));
const PatientDashboard = lazy(() =>
  import("../Pages/Patient/PatientDashboard")
);
const PatientAccount = lazy(() => import("../Pages/Patient/Account"));
const PatientReportDashboard = lazy(() =>
  import("../Pages/Patient/Report/Dashboard")
);
const PatientRegistrationPage = lazy(() =>
  import("../Pages/Auth/PatientRegistrationPage")
);
const PatientPastReports = lazy(() =>
  import("../Pages/Patient/Report/PastReport")
);
const Questionnaire = lazy(() => import("../Pages/Patient/Questionnaire"));
const LabUpload = lazy(() => import("../Pages/Practice/LabUpload"));
const PracticeGeneticUpload = lazy(() =>
  import("../Pages/Practice/GeneticUpload")
);
const ReportSummary = lazy(() => import("../Pages/Report/ReportSummary"));
const ReportDiagrams = lazy(() => import("../Pages/Report/ReportDiagrams"));
const GeneAnalysisSummary = lazy(() =>
  import("../Pages/Report/GeneAnalysisSummary")
);
const SupplementalGenotype = lazy(() =>
  import("../Pages/Report/SupplementalGenotype")
);
const ResetPasswordPage = lazy(() => import("../Pages/Auth/ResetPasswordPage"));
const Catalog = lazy(() => import("../Pages/Practice/Catalog"));
const TraitPage = lazy(() => import("../Pages/Report/TraitsPage"));
const ReviewLabResult = lazy(() =>
  import("../components/Practice/Lab/ReviewLabResult")
);
const PastReport = lazy(() => import("../Pages/Report/PastReport"));
const ReviewQuestion = lazy(() => import("../Pages/Patient/ReviewQuestion"));
const ThankYouQuestionnaire = lazy(() =>
  import("../Pages/Patient/ThankYouQuestionnaire")
);
const OtpVerificationPage = lazy(() =>
  import("../Pages/Auth/OtpVerificationPage")
);
const ManualEntry = lazy(() =>
  import("../components/Practice/Lab/ManualEntry")
);
const PatientManualEntry = lazy(() =>
  import("../components/Patient/Lab/ManualEntry")
);
const _404 = lazy(() => import("../Pages/_404"));
const DiscoverPG = lazy(() =>
  import("../components/StaticPages/Provider/DiscoverPG")
);
const KnowTheScience = lazy(() =>
  import("../components/StaticPages/Provider/KnowTheScience")
);
const Registration = lazy(() =>
  import("../components/StaticPages/Provider/Registration")
);
const LegalDesclaimer = lazy(() =>
  import("../components/StaticPages/LegalDesclaimer")
);
const Privacy = lazy(() => import("../components/StaticPages/Privacy"));
const TermsAndUse = lazy(() => import("../components/StaticPages/TermsAndUse"));
const DispensaryTermsAndUse = lazy(() =>
  import("../components/StaticPages/DispensaryTermsAndUse")
);
const ContactUs = lazy(() => import("../components/StaticPages/ContactUs"));
const EnhanceYourPractice = lazy(() =>
  import("../components/StaticPages/Provider/EnhanceYourPractice")
);
const EducationalScience = lazy(() =>
  import("../components/StaticPages/Provider/EducationalScience")
);
const AboutPE = lazy(() =>
  import("../components/StaticPages/Provider/AboutPE")
);
const HowToGetStarted = lazy(() =>
  import("../components/StaticPages/Provider/HowToGetStarted")
);
const ProviderFAQ = lazy(() =>
  import("../components/StaticPages/Provider/ProviderFAQ")
);
const WhyPG = lazy(() => import("../components/StaticPages/Patient/WhyPG"));
const WhatYouGet = lazy(() =>
  import("../components/StaticPages/Patient/WhatYouGet")
);
const PatientGetStarted = lazy(() =>
  import("../components/StaticPages/Patient/PatientGetStarted")
);
const AboutPEPatient = lazy(() =>
  import("../components/StaticPages/Patient/AboutPEPatient")
);
const PatientFAQ = lazy(() =>
  import("../components/StaticPages/Patient/PatientFAQ")
);
const RialtoPractitionerFAQ = lazy(() =>
  import("../components/StaticPages/RialtoPractitionerFAQ")
);
const RialtoPatientFAQ = lazy(() =>
  import("../components/StaticPages/RialtoPatientFAQ")
);
const CustomerService = lazy(() =>
  import("../components/StaticPages/CustomerService")
);
const GdprFAQ = lazy(() => import("../components/StaticPages/Patient/GdprFAQ"));
const MeetOutTeam = lazy(() => import("../components/StaticPages/MeetOutTeam"));
const PostLogin = lazy(() => import("../components/StaticPages/PostLogin"));
const ResearchAbstract = lazy(() =>
  import("../components/StaticPages/ResearchAbstract")
);
const UKProviderFAQ = lazy(() =>
  import("../components/StaticPages/Provider/UKProviderFAQ")
);
const CAProviderFAQ = lazy(() =>
  import("../components/StaticPages/Provider/CAProviderFAQ")
);
const CAPatientFAQ = lazy(() =>
  import("../components/StaticPages/Patient/CAPatientFAQ")
);
const UKPatientFAQ = lazy(() =>
  import("../components/StaticPages/Patient/UKPatientFAQ")
);
const UKMeetOutTeam = lazy(() =>
  import("../components/StaticPages/UKMeetOutTeam")
);
const CAMeetOutTeam = lazy(() =>
  import("../components/StaticPages/CAMeetOutTeam")
);
const TermsAndUseCA = lazy(() =>
  import("../components/StaticPages/TermsAndUseCA")
);
const TermsAndUseUK = lazy(() =>
  import("../components/StaticPages/TermsAndUseUK")
);
const PrivacyUK = lazy(() => import("../components/StaticPages/PrivacyUK"));
const PrivacyCA = lazy(() => import("../components/StaticPages/PrivacyCA"));
const ManageDataUK = lazy(() => import("../Pages/Patient/ManageDataUK"));
const PracticeManageDataUK = lazy(() =>
  import("../Pages/Practice/PracticeManageDataUK")
);
const PracticeGDPRPage = lazy(() =>
  import("../Pages/Practice/PracticeGDPRPage")
);
const PracticePatientGDPRLogs = lazy(() =>
  import("../Pages/Practice/PracticePatientGDPRLogs")
);
const UKCookiesNotice = lazy(() =>
  import("../components/StaticPages/UKCookiesNotice")
);
const AdminPracList = lazy(() =>
  import("../Pages/Admin/GDPR/AdminPracGDPRList")
);
const AdminPatientGDPRList = lazy(() =>
  import("../Pages/Admin/GDPR/AdminPatientGDPRList")
);
const AdminPracGDPRList = lazy(() =>
  import("../Pages/Admin/GDPR/AdminPracGDPRList")
);
const PractitionerReport = lazy(() =>
  import("../Pages/Admin/CSVReport/PractitionerReport")
);
const PatientReport = lazy(() =>
  import("../Pages/Admin/CSVReport/PatientReport")
);
const PrsScorePatient = lazy(() => import("../Pages/Practice/PrsScorePatient"));
const PasswordUpdatePage = lazy(() =>
  import("../Pages/Auth/PasswordUpdatePage")
);
const CACookiesNotice = lazy(() =>
  import("../components/StaticPages/CACookiesNotice")
);
const MSQ = lazy(() => import("../Pages/PG4/MSQuestionnaires"));
const PatientLabUpload = lazy(() =>
  import("../Pages/Patient/PatientLabUpload")
);
const PG4Dashboard = lazy(() => import("../Pages/PG4/Dashboard"));
const AccountSection = lazy(() => import("../Pages/PG4/AccountSection"));
const PatientLayout = lazy(() => import("./PatientLayout"));
const ReportSummaryPage = lazy(() => import("../Pages/PG4/ReportSummaryPage"));
const PatientReviewLabResult = lazy(() =>
  import("../components/Practice/Lab/PatientReviewLabResult")
);
const MSQStatus = lazy(() => import("../Pages/PG4/MSQStatus"));
const HealthDatNotice = lazy(() =>
  import("../components/StaticPages/HealthDataNotice")
);
const PatientSelfRegister = lazy(() =>
  import("../Pages/Auth/PatientSelfRegister")
);
const AddPatientBulkPage = lazy(() =>
  import("../Pages/Practice/AddPatientBulkPage")
);



function MainLayout() {
  // redux state variable
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userType = useSelector((state) => state.auth.userType);
  const inHomeApp = useSelector((state) => state.auth.inHomeApp);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const otp = useSelector((state) => state.auth.otp);
  const lang = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/loading" element={<Loading />} />
          <Route>
            {/*Home page route*/}
            <Route element={<HomePageLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/trait-category-abstracts"
                element={<ResearchAbstract />}
              />
              <Route path="/hcp/educational_1" element={<PostLogin />} />
              <Route path="/hcp/about-puregenomics" element={<DiscoverPG />} />
              <Route path="/hcp/about-pureinsight" element={<DiscoverPG />} />
              <Route
                path="/hcp/discover-pureinsight"
                element={<DiscoverPG />}
              />
              <Route
                path="/hcp/snps-genetic-testing"
                element={<KnowTheScience />}
              />
              <Route
                path="/hcp/practitioner-benefits"
                element={<EnhanceYourPractice />}
              />
              <Route path="/hcp/educational-page" element={<PostLogin />} />
              <Route path="/hcp/nutritional-support" element={<AboutPE />} />
              <Route
                path="/hcp/getting-started"
                element={<HowToGetStarted />}
              />
              <Route path="/hcp/registration" element={<Registration />} />
              <Route
                path="/hcp/faqs"
                element={
                  i18n.language === "en_gb" ? (
                    <UKProviderFAQ />
                  ) : (
                    <ProviderFAQ />
                  )
                }
              />
              <Route path="/hcp-faqs" element={<CAProviderFAQ />} />
              <Route path="/patient/why-puregenomics" element={<WhyPG />} />
              <Route path="/patient/why-pureinsight" element={<WhyPG />} />
              <Route path="/patient/what-you-get" element={<WhatYouGet />} />
              <Route
                path="/patient/how-to-get-started"
                element={<PatientGetStarted />}
              />
              <Route
                path="/patient/faqs"
                element={
                  i18n.language === "en_gb" ? <UKPatientFAQ /> : <PatientFAQ />
                }
              />
              <Route path="/patient-faqs" element={<CAPatientFAQ />} />
              <Route path="/patient/gdpr-faqs" element={<GdprFAQ />} />
              <Route
                path="/patient/nutritional-support"
                element={<AboutPEPatient />}
              />
              <Route
                path="/patient/customer-service"
                element={<CustomerService />}
              />
              <Route
                path="/patient/self-register"
                element={<PatientSelfRegister />}
              />
              <Route path="/legal-disclaimer" element={<LegalDesclaimer />} />
              <Route
                path="/health-data-notice"
                element={
                  i18n.language === "en" ? <HealthDatNotice /> : <_404 />
                }
              />
              <Route path="/disclaimer" element={<LegalDesclaimer />} />
              <Route
                path="/privacy"
                element={
                  i18n.language === "en_gb" ? <PrivacyUK /> : <Privacy />
                }
              />
              <Route path="/privacy-policy" element={<PrivacyCA />} />
              <Route
                path="/terms-use"
                element={
                  i18n.language === "en_gb" ? (
                    <TermsAndUseUK />
                  ) : (
                    <TermsAndUse />
                  )
                }
              />
              <Route path="/terms-of-service" element={<TermsAndUseCA />} />
              <Route
                path="/dispensary-terms-use"
                element={<DispensaryTermsAndUse />}
              />
              <Route
                path="/practitioner-faq"
                element={<RialtoPractitionerFAQ />}
              />
              <Route
                path="/patient/patient-faq"
                element={<RialtoPatientFAQ />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/our-team"
                element={
                  i18n.language === "en_gb" ? (
                    <UKMeetOutTeam />
                  ) : i18n.language === "ca" ? (
                    <CAMeetOutTeam />
                  ) : (
                    <_404 />
                  )
                }
              />
              <Route
                path="/cookies-notice"
                element={
                  i18n.language === "en_gb" ? (
                    <UKCookiesNotice />
                  ) : (
                    <CACookiesNotice />
                  )
                }
              />
              <Route path="/*" element={<_404 />} />
            </Route>
            {/*Login page route*/}
            <Route
              element={
                <LoginRoute
                  isAuth={isAuth}
                  mfaEnabled={mfaEnabled}
                  userType={userType}
                  inHomeApp={inHomeApp}
                  otp={otp}
                />
              }
            >
              <Route element={<LoginLayout />}>
                <Route path="/users/login" element={<LoginPage />} />
                <Route path="/sign-in" element={<LoginPage />} />
                <Route path="/password/edit" element={<ResetPasswordPage />} />
                <Route path="/users/fp" element={<ForgotPasswordPage />} />
                <Route path="/users/up" element={<PasswordUpdatePage />} />
                <Route
                  path="/patients/invitation/accept"
                  element={<PatientRegistrationPage />}
                />
                <Route
                  path="/invitation/accept"
                  element={<PatientRegistrationPage />}
                />
                <Route
                  path="/admin_users/invitation/accept"
                  element={<PatientRegistrationPage />}
                />
                <Route
                  path="/pg2_invitation/accept"
                  element={<PG2PasswordUpdatePage />}
                />
                <Route
                  path="/admin_users/invitation/accept"
                  element={<PatientRegistrationPage />}
                />
                <Route
                  path="/pg2_user_landing_page"
                  element={<Pg2UserLandingPage />}
                />
                <Route path="/*" element={<_404 />} />
              </Route>
            </Route>
            {/*MFA page route*/}
            <Route
              element={
                <MFARoute
                  isAuth={isAuth}
                  mfaEnabled={mfaEnabled}
                  userType={userType}
                  inHomeApp={inHomeApp}
                  otp={otp}
                />
              }
            >
              <Route element={<LoginLayout />}>
                <Route
                  path="/users/verify_otp"
                  element={<OtpVerificationPage />}
                />
                <Route path="/users/domain" element={<DomainPage />} />
                <Route path="/*" element={<_404 />} />
              </Route>
            </Route>

            {/*Admin page route*/}
            <Route
              element={
                <AdminProtectedRoute
                  isAuth={isAuth}
                  mfaEnabled={mfaEnabled}
                  userType={userType}
                  inHomeApp={inHomeApp}
                  otp={otp}
                />
              }
            >
              <Route element={<AdminLayout />}>
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route
                  path="/admin/practitioners"
                  element={<AdminPractitionerList />}
                />
                <Route path="/admin/patients" element={<AdminPatientList />} />
                <Route
                  path="/admin/report/practitioner"
                  element={<PractitionerReport />}
                />
                <Route
                  path="/admin/report/patient"
                  element={<PatientReport />}
                />
                <Route path="/admin/users" element={<AdminUserList />} />
                <Route
                  path="/admin/gdpr/patient"
                  element={<AdminPatientGDPRList />}
                />
                <Route
                  path="/admin/gdpr/practitioner"
                  element={<AdminPracGDPRList />}
                />
                <Route
                  path="/admin/production_task"
                  element={<ProductionTask />}
                />
                <Route path="/admin/staffs" element={<AdminStaffList />} />
                <Route path="/*" element={<_404 />} />
              </Route>
            </Route>
            {/*Practice page route*/}
            <Route
              element={
                <PracticeProtectedRoute
                  isAuth={isAuth}
                  mfaEnabled={mfaEnabled}
                  userType={userType}
                  inHomeApp={inHomeApp}
                  otp={otp}
                />
              }
            >
              <Route element={<PracticeLayout />}>
                <Route path="/loading" element={<Loading />} />
                <Route
                  path="/practice/patient/add"
                  element={<AddPatientPage />}
                />
                <Route
                  path="/practice/patient/addbulk"
                  element={<AddPatientBulkPage />}
                />
                <Route path="/practice/patient/catalog" element={<Catalog />} />
                <Route
                  path="/practice/patient/:patient_id/report/:report_id"
                  element={<ReportSummary />}
                />
                <Route
                  path="/practice/patient/:patient_id/report/:report_id/diagram"
                  element={<ReportDiagrams />}
                />
                <Route
                  path="/practice/patient/:patient_id/report/:report_id/supplemental_genotype"
                  element={<SupplementalGenotype />}
                />
                {/* <Route path="/practice/patient/report/gene_analysis" element={<GeneAnalysisSummary />} /> */}
                <Route
                  path="/practice/patient/:patient_id/report/:report_id/trait"
                  element={<TraitPage />}
                />
                <Route
                  path="/practice/patient/report/ps_list/:report_id"
                  element={<PrsScorePatient />}
                />
                <Route
                  path="/practice/patient/lab/review"
                  element={<ReviewLabResult />}
                />
                <Route path="/practice/patient/lab" element={<LabUpload />} />
                <Route
                  path="/practice/patient/lab/manual_entry"
                  element={<ManualEntry />}
                />
                <Route
                  path="/practice/patient/snp"
                  element={<PracticeGeneticUpload />}
                />
                <Route
                  path="/practice/patient/past_report"
                  element={<PastReport />}
                />
                <Route
                  path="/practice/patients/gdpr"
                  element={<PracticePatientGDPRLogs />}
                />
                <Route path="/practice/patients" element={<PatientList />} />
                <Route
                  path="/practice/supplement_catalog"
                  element={<RialtoPage />}
                />
                <Route
                  path="/practice/view_patient_answers"
                  element={<ViewPatientAnswers />}
                />
                <Route
                  path="/practice/dashboard"
                  element={<PractitionerDashboard />}
                />
                <Route
                  path="/practice/account"
                  element={<PractitionerAccount />}
                />
                <Route
                  path="/practice/rialto_management"
                  element={<RialtoManagement />}
                />
                <Route
                  path="/practice/rialto_management_new"
                  element={<RialtoPage />}
                />
                <Route
                  path="/practice/manage_data"
                  element={lang === "en_gb" ? <ManagePracticeData /> : <_404 />}
                />
                <Route
                  path="/practice/stripe_redirect"
                  element={<StripeRedirect />}
                />
                <Route
                  path="/practice/order/:orderId"
                  element={<RialtoPage />}
                />
                <Route path="/practice/staff" element={<StaffList />} />
                <Route path="/staff/account" element={<StaffAccount />} />
                <Route path="/users/domain_pl" element={<DomainPage />} />
                <Route
                  path="/practice/report/new/:report_id"
                  element={<ReportSummaryPage />}
                />
                <Route path="/*" element={<_404 />} />
              </Route>
            </Route>
            {/*Patient page route*/}
            <Route
              element={
                <PatientRoute
                  isAuth={isAuth}
                  mfaEnabled={mfaEnabled}
                  userType={userType}
                  inHomeApp={inHomeApp}
                  otp={otp}
                />
              }
            >
              <Route element={<PatientLayout />}>
                <Route
                  path="/patient/:patient_id/report/:report_id"
                  element={<ReportSummary />}
                />
                <Route
                  path="/patient/:patient_id/report/:report_id/trait"
                  element={<TraitPage />}
                />
                <Route
                  path="/patient/supplement_catalog"
                  element={<RialtoPage />}
                />
                <Route path="/patient/snp" element={<GeneticUpload />} />
                <Route path="/patient/dashboard" element={<PG4Dashboard />} />
                <Route path="/patient/account" element={<PatientAccount />} />
                <Route path="/patient/qpro_questionnaire" element={<QproPage />} />
                <Route
                  path="/patient/questionnaire"
                  element={<Questionnaire />}
                />
                <Route
                  path="/patient/review_answer"
                  element={<ReviewQuestion />}
                />
                <Route
                  path="/patient/thank_you"
                  element={<ThankYouQuestionnaire />}
                />
                <Route
                  path="/patient/supplement_suggestions"
                  element={<RialtoPage />}
                />
                <Route
                  path="/patient/order/:orderId"
                  element={<RialtoPage />}
                />
                <Route
                  path="/patient/manage_data"
                  element={lang === "en_gb" ? <ManageDataUK /> : <_404 />}
                />
                <Route
                  path="/patient/dashboard"
                  element={<PatientReportDashboard />}
                />
                <Route
                  path="/patient/past_reports"
                  element={<PatientPastReports />}
                />
                <Route path="/patient/msq/status" element={<MSQStatus />} />
                <Route path="/patient/msq" element={<MSQ />} />
                {/* <Route path="/patient/dashboard_v4" element={<PG4Dashboard />} /> */}
                <Route path="/patient/lab" element={<PatientLabUpload />} />
                <Route
                  path="/patient/lab/manual_entry"
                  element={<PatientManualEntry />}
                />
                <Route
                  path="/patient/lab/review"
                  element={<PatientReviewLabResult />}
                />
                <Route
                  path="/patient/report/new/:report_id"
                  element={<ReportSummaryPage />}
                />
                <Route path="/patient/shipping_cart" element={<RialtoPage />} />
                <Route path="/patient/order_history" element={<RialtoPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="/*" element={<_404 />} />
        </Routes>
      </Suspense>
      <Footer userType={userType} />
    </>
  );
}

export default MainLayout;
