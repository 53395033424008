import ContentLoader from "react-content-loader";
function L_QproPage() {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            // height="20%"
            viewBox="0 0 600 900"
            backgroundColor="#9e9e9e52"
            foregroundColor="#18368f80"
        >
            <rect x="200" y="20" rx="3" ry="3" width="200" height="20" />
            <rect x="300" y="50" rx="3" ry="3" width="100" height="20" />

            <rect x="200" y="80" rx="3" ry="3" width="200" height="20" />
            <rect x="300" y="110" rx="3" ry="3" width="100" height="20" />

            <rect x="200" y="140" rx="3" ry="3" width="200" height="20" />
            <rect x="300" y="170" rx="3" ry="3" width="100" height="20" /> 

            <rect x="200" y="200" rx="3" ry="3" width="200" height="20" />
            <rect x="300" y="230" rx="3" ry="3" width="100" height="20" />           


        </ContentLoader>
    )
}

export default L_QproPage
